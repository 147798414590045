import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

function onSubmit(token) {
  document.getElementById("contact").submit()
}
const ContactPage = () => (
  <Layout>
    <SEO 
      title="Contact"
      description="If you have any questions, comments, or concerns, we're here to help. Get in touch with the Pluralistic team today to learn more about our programs."
    />
    <Helmet>
      {/* <script src="https://www.google.com/recaptcha/api.js"></script> */}
    </Helmet>
    <div
      class="maxWidth pagePadding"
      style={{
        marginTop: "46px",
      }}
    >
      <div style={{ marginBottom: "50px", textAlign: "center" }}>
        <span class="pageTitle2"> Contact us! </span>
      </div>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        // onSubmit={onSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div class="field">
          <label class="label">First name*</label>
          <div class="control">
            <input
              type="text"
              class="input"
              name="firstName"
              id="name"
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Last name*</label>
          <div class="control">
            <input
              type="text"
              class="input"
              name="lastName"
              id="name"
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Email*</label>
          <div class="control">
            <input
              type="email"
              class="input"
              name="email"
              id="email"
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Message*</label>
          <div class="control">
            <textarea
              class="textarea"
              id="message"
              name="message"
              required
            ></textarea>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
          class="has-text-centered field "
        >
          <button
            style={{
              textAlign: "center", marginTop: "36px" }}
            class="contactButton"
            // data-sitekey="6Lfic7YZAAAAAI8FAowsfWuYi64oCHlSNfU9jOwP"   g-recaptcha
            // data-callback="onSubmit"
            // data-action="submit"
            
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </div>
    <div style={{ marginTop: "48px" }} class="topLine">
      &nbsp;
    </div>
  </Layout>
)

export default ContactPage
